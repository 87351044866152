.container {
  display: flex;
  flex-direction: column;
  padding: 25px 44px 63px 44px;
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-family: 'Roboto';
}

.title {
  align-self: center;
  margin-bottom: 25px;
  line-height: 59px;
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
}

label {
  margin-bottom: 10px;
  line-height: 23px;
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 165px 24px 24px;
  border: 1px solid var(--black);
  border-radius: 10px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}

.input::placeholder{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
}

.emailContainer {
  margin-bottom: 20px;
}

.passwordContainer {
  position: relative;
  margin-bottom: 35px;
}

.inputContainer {
  position: relative;
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

.submitButton, .guestButton {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 30px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
  background: var(--blue);
  color: var(--white);
}

.submitButton {
  margin-bottom: 15px;
}

.linkContainer {
  text-align: center;
}

.linkContainer span a {
  text-decoration: none;
  color: var(--blue);
}

.linkContainer div {
  margin-bottom: 15px;
}

.navContainer p {
  font-size: 16px;
}

.error {
  margin: 10px 0 0 0;
  color: var(--error);
}

@media screen and (max-width:767px) {
  .container {
    padding: 10px 12px 23px;
  }

  .title {
    margin-bottom: 0;
    font-size: 24px;
  }

  label {
    font-size: 12px;
    font-weight: medium;
  }

  .input {
    padding: 11px 15px;
  }

  .input::placeholder{
    font-weight: 400;
    font-size: 10px;
    line-height: 19px;
  }

  .submitButton, .guestButton {
    padding: 7px 78px;
    font-size: 12px;
    font-weight: medium;
  }

  .navContainer p {
    font-size: 10px;
  }

  .error {
    font-size: 10px;
    margin-bottom: 5px;
  }
}

