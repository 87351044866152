.container {
   display: flex;
   justify-content: space-around;
   padding: 32px 100px 60px 100px;
}

.titleContainer {
   align-self: center;
}

.bannerContainer {
   width: 32vw;
}

.container p {
   margin: 0;
   font-family: 'Roboto';
   font-weight: 700;
   font-size: 7vw;
   line-height: 8vw;
}

@media screen and (max-width:767px) {
   .container {
      padding: 57px 32px 22px 32px;
   }

   .titleContainer {
      align-self: center;
   }
   
   .container p {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
   }
}