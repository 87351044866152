.navContainer {
    flex-grow: 1;
}

.listContainer {
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 23px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Roboto';
    color: var(--black);
}

.listContainer li {
    justify-self: center;
    flex-grow: .15;
}

.listContainer a {
    text-decoration: none;
}

.active {
    pointer-events: none;
    color: var(--black);
}

.inActive {
    color: var(--blue);
}

@media screen and (max-width: 767px) {
    .listContainer {
        flex-direction: column;
        font-size: 12px;
    }

    .listContainer li {
        margin-bottom: 12px;
        padding: 0;
        text-align: center;
    }

    .listContainer li:last-child {
        margin-bottom: 23px;
    }
    .listContainer li button {
        margin-bottom: 20px;
    }
}