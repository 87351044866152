.container {
    min-height: 100vh;
    padding: 65px 100px 100px 100px;
    background-color: var(--grey);
    font-family: 'Roboto';
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
}

.headerContainer > p {
    align-self: center;
    margin: 0;
    line-height: 35px;
    font-size: 30px;
    font-weight: 700;
    color: var(--blue);
}

.headerContainer > p span {
    color:var(--black)
}

.listContainer {
    display: flex;
    flex-wrap: wrap;
} 

.card {
    margin: 4px 8px;
    padding: 4px 8px;
    list-style: none;
    background-color: var(--grey);
}

.listMessage {
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    .container {
        padding: 50px 32px;
    }
    
    .listContainer {
        justify-content: center;
        padding: 0;
    }
    
    .headerContainer {
        padding-bottom: 5px;
    }

    .headerContainer > p {
        font-size: 16px;
    }

    .listMessage {
        font-size: 10px;
    }
} 
    
