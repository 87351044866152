body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    --blue: #409DE1;
    --black: #000;
    --grey: #f9f9f9;
    --white: #fff;
    --error: red;
    font-family: 'Roboto', sans-serif;
}