@import url('https://fonts.googleapis.com/css?family=Roboto');

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
}

p, .button {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 236px;
  margin: 15px 22px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  overflow: hidden;
  font-family: 'Roboto';
}

.posterContainer {
  flex: 1;
}

.poster {
  width: 100%;
}

.descriptionContainer {
  padding: 10px 16px 16px 10px;
}

.date {
  margin-bottom: 5px;
}

.popularityContainer {
  display: flex;
  margin-bottom: 10px;
}

.popularityContainer p {
  align-self: center;
  margin: 0;
  padding-left: 5px;
}

.buttonContainer {
  display: flex ;
  justify-content: center;
}

.button {
  min-width: 84px;
  margin: 0 10px;
  padding: 11px 20px;
  border-radius: 6px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--blue);
  color: var(--white);
}

.disabled {
  color: rgba(0, 0, 0, 0.3);
  background: var(--white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
  
@media screen and (max-width: 767px) {
  .container {
    max-width: 236px;
  }

  .descriptionContainer {
    padding: 5px 11px 16px 11px;
  }
}




 



   



